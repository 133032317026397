import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Технічна Підтримка 360
			</title>
			<meta name={"description"} content={"Швидкі рішення, надійні результати"} />
			<meta property={"og:title"} content={"Технічна Підтримка 360"} />
			<meta property={"og:description"} content={"Швидкі рішення, надійні результати"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/669925669710450018a1672b/images/mobile-device-repair.webp?v=2024-07-18T14:35:15.809Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Components.Header1>
			<Override slot="link" />
			<Override slot="text" />
		</Components.Header1>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Пропозиція від Технічна Підтримка 360
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					В Технічна Підтримка 360 PC Repair ми пишаємося тим, що пропонуємо повний спектр послуг, які відповідають всім вашим потребам у ремонті комп'ютерів. Наші послуги спрямовані на забезпечення максимальної ефективності і надійності та підвищення терміну служби вашої техніки. Ознайомтеся з нашими детальними пропозиціями та знайдіть ідеальне рішення для вашої технічної проблеми.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14:35:15.810Z"
					max-height="400px"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/repairman-uses-magnifier-tweezers-repair-damaged-smartphone-close-up-photo-disassembled-smartphone-scaled.jpg?v=2024-07-18T14%3A35%3A15.810Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Діагностика та усунення несправностей
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Аналіз помилок: Швидке виявлення програмних та апаратних помилок.
Оптимізація продуктивності: Покращення швидкості та ефективності.
Регулярні технічні перевірки: Запобігайте майбутнім проблемам з планованими діагностиками.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14:35:15.821Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14%3A35%3A15.821Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14%3A35%3A15.821Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14%3A35%3A15.821Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14%3A35%3A15.821Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14%3A35%3A15.821Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14%3A35%3A15.821Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/5.jpg?v=2024-07-18T14%3A35%3A15.821Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Ремонт та оновлення апаратного забезпечення
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Заміна компонентів: Від жорстких дисків до відеокарт ми використовуємо тільки кращі частини.
Оновлення системи: Підвищуйте продуктивність вашого ПК за допомогою найновішої технології.
Індивідуальні збірки: Налаштовані системи, призначені для ваших конкретних потреб.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				lg-width="40%"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-min-width="64px"
				empty-border-color="LightGray"
				width="33%"
			>
				<Image
					src="https://uploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14:35:15.798Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/Device-Repair.png?v=2024-07-18T14%3A35%3A15.798Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
				>
					Встановлення та налаштування програмного забезпечення
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Встановлення операційної системи: Безпроблемне налаштування систем Windows, Mac або Linux.
Оновлення програмного забезпечення: Відтримуйте ваші програми та операційні системи в актуальному стані.
Засоби захисту: Встановлення антивірусного та захисту від шкідливих програм для захисту ваших даних.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
				md-width="100%"
				lg-width="60%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-width="1px"
				width="33%"
				md-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					object-position="bottom"
					srcSet="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box sm-padding="0px 0px 0px 0px" md-padding="0px 0px 0px 0px" padding="0px 30% 0px 0px">
					<Text
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Управління даними
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--light"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						text-align="left"
					>
						Відновлення даних: Ефективне відновлення втрачених або пошкоджених даних.
Резервні копії: Налаштуйте надійні системи резервного копіювання для запобігання втраті даних.
Перенесення даних: Безпечна міграція даних між пристроями.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
				md-width="100%"
				lg-width="60%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-width="1px"
				width="33%"
				md-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14:35:15.779Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14%3A35%3A15.779Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14%3A35%3A15.779Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14%3A35%3A15.779Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14%3A35%3A15.779Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14%3A35%3A15.779Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14%3A35%3A15.779Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/669925669710450018a1672b/images/4.jpg?v=2024-07-18T14%3A35%3A15.779Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box sm-padding="0px 0px 0px 0px" md-padding="0px 0px 0px 0px" padding="0px 30% 0px 0px">
					<Text
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Налаштування та управління мережею
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--light"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						text-align="left"
					>
						Домашні та офісні мережі: Налаштування та обслуговування надійних з'єднань.
Протоколи безпеки: Впровадження міцної мережевої безпеки для захисту конфіденційної інформації.
Оптимізація підключення: Забезпечення стабільного та швидкого Інтернет-з'єднання.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0">
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 16px 0px"
				>
					Дізнайтеся більше з Технічна Підтримка 360
				</Text>
				<Text
					color="--light"
					font="--base"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					text-align="left"
				>
					Цей огляд - лише маленький взгляд на те, як ми можемо оновити ваше комп'ютерне досвід. Щоб повністю дослідити нашу широку гаму послуг і дізнатися, як ми можемо вам конкретно допомогти, ми запрошуємо вас зв'язатися з нами або відвідати наш магазин. Наші експерти готові надати вам особисті консультації та індивідуальні рішення.
					<br />
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="linkBox" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text3" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="text7" />
			<Override slot="text8" />
			<Override slot="text9" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});